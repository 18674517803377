import React, { useCallback, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Button, ConfigProvider } from 'antd';
import cn from 'classnames';
import { Provider } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ABOUT_ROUTE, DASHBOARD_ROUTE, FAQ_ROUTE, PRICING_ROUTE } from 'routes/dashboard/list';
import { CREATE_PROPERTY_FORM_ROUTE } from 'routes/property/list';
import { LOGIN_ROUTE, PROFILE_ROUTE } from 'routes/user-management/list';
import { getIsLoggedIn, removeTokenCookies } from 'tools/cookieTools';
import { useQuery, useSetQuery } from 'tools/hooks';
import { Dropdown, Icon } from 'ui';

import { isOpenedSiderSelector } from 'selectors/systemSlice.selector';
import { ProfileSelector, isLoggedInSelector } from 'selectors/userSlice.selectors';
import { setActiveProperty, setTerms } from 'slices/propertySlice';
import { setOpenedSider } from 'slices/systemSlice';
import { logout } from 'slices/userSlice';
import { persistor, store, useAppDispatch, useAppSelector } from 'store';

import Footer from 'containers/footer';
import NavBar from 'containers/nav-bar';
import Filters from 'containers/nav-bar/filters';

import s from './App.module.scss';
import Pages from './pages';

const DROPDOWN_ITEMS = () => [
  { key: 'post', label: <div className={s.dropdownItem}>Post for free</div> },
  { key: 'profile', label: <div className={s.dropdownItem}>Profile</div> },
];

function App() {
  const isAuth = getIsLoggedIn();
  const navigate = useNavigate();
  const isLogged = useAppSelector(isLoggedInSelector);
  const initiallyLogged = isLogged || isAuth;
  const dispatch = useAppDispatch();
  const isOpenedSidebar = useAppSelector(isOpenedSiderSelector);
  const setQuery = useSetQuery();
  const query = useQuery();
  const step = query.get('step');
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const profile = useAppSelector(ProfileSelector);

  const handleClick = useCallback(() => {
    dispatch(setOpenedSider(!isOpenedSidebar));
  }, [isOpenedSidebar, dispatch]);
  const keyPressHandler = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      return;
    }
  };

  const goToDashboard = useCallback(() => {
    dispatch(setOpenedSider(false));
    navigate(DASHBOARD_ROUTE);
  }, [navigate, dispatch]);

  const handleGoToAbout = useCallback(() => {
    dispatch(setOpenedSider(false));
    navigate(ABOUT_ROUTE);
  }, [navigate, dispatch]);

  const handleGoToFaq = useCallback(() => {
    dispatch(setOpenedSider(false));
    navigate(FAQ_ROUTE);
  }, [navigate, dispatch]);

  const handleGoToPricing = useCallback(() => {
    dispatch(setOpenedSider(false));
    navigate(PRICING_ROUTE);
  }, [navigate, dispatch]);

  const handleChangeStep = useCallback(
    (step: string) => {
      setQuery(step);
    },
    [setQuery],
  );

  const handleLogout = useCallback(() => {
    dispatch(setTerms(undefined));
    dispatch(setOpenedSider(false));
    dispatch(logout());
    if (removeTokenCookies()) {
      window.location.reload();
      navigate(LOGIN_ROUTE);
    }
  }, [dispatch, navigate]);

  const goToProperty = useCallback(() => {
    console.log('GOTO');
    dispatch(setOpenedSider(false));
    dispatch(setActiveProperty(undefined));
    navigate(CREATE_PROPERTY_FORM_ROUTE);
  }, [navigate, dispatch]);

  const handleGoToProfile = useCallback(() => {
    dispatch(setOpenedSider(false));
    navigate(`${PROFILE_ROUTE}?step=userProfile`);
  }, [navigate, dispatch]);

  const handleItemClick = useCallback(
    (ev: { key: string }) => {
      console.log(ev);
      if (ev.key === 'post') {
        goToProperty();
      } else if (ev.key === 'profile') {
        handleGoToProfile();
      }
    },
    [goToProperty, handleGoToProfile],
  );

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#8E53E8',
              borderRadius: 8,
              colorTextBase: '#000000',
              colorLinkHover: '#B989FF',
              colorLinkActive: '#000000',
            },
          }}>
          <div className="App">
            <div>
              <div className={s.content}>
                {initiallyLogged && (
                  <div className={cn(s.mobileHeader)}>
                    <button className={s.menu} onClick={handleClick}>
                      <MenuOutlined />
                    </button>
                    <div className={s.logo}>
                      <Icon name="logo" width={142} height={20} />
                    </div>
                    <Dropdown
                      items={DROPDOWN_ITEMS()}
                      placement="bottomRight"
                      open={openedDropdown}
                      onOpenChange={setOpenedDropdown}
                      handleItemClick={handleItemClick}>
                      <div className={s.profile}>
                        {profile?.profileImageURL &&
                          profile?.profileImageURL !==
                            'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/' && (
                            <div className={s.uploadedFile}>
                              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <img
                                  className={s.uploadedImage}
                                  src={profile?.profileImageURL}
                                  width={28}
                                  height={28}
                                  alt="promotionMedia"
                                />
                              </div>
                            </div>
                          )}
                        {!profile?.profileImageURL ||
                          (profile?.profileImageURL ===
                            'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/' && (
                            <div className={s.uploadedFile}>
                              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                <img
                                  className={s.uploadedImage}
                                  src="/assets/images/man.png"
                                  width={28}
                                  height={28}
                                  alt="promotionMedia"
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </Dropdown>
                  </div>
                )}
                <Pages />
                {initiallyLogged && <Footer />}
              </div>
              {initiallyLogged && (
                <>
                  <div className={s.navBar}>
                    <NavBar initiallyLogged={initiallyLogged} />
                  </div>
                  {location.pathname === '/dashboard' && (
                    <div className={s.filterWrapper}>
                      <Filters />
                    </div>
                  )}
                  {isOpenedSidebar && (
                    <>
                      <div
                        className={s.shadow}
                        role="button"
                        tabIndex={0}
                        onKeyPress={keyPressHandler}
                        onClick={() => {
                          dispatch(setOpenedSider(false));
                        }}></div>
                    </>
                  )}
                  <div className={cn(s.mobileMenu, { [s.opened]: isOpenedSidebar })}>
                    {location.pathname !== '/profile' && (
                      <div className={s.menuContent}>
                        <Button
                          type="link"
                          onClick={goToDashboard}
                          className={`${s.menuItem} ${
                            location.pathname === '/buy' ? s.active : ''
                          }`}>
                          Buy
                        </Button>
                        <Button
                          type="link"
                          onClick={goToDashboard}
                          className={`${s.menuItem} ${
                            location.pathname === '/rent' ? s.active : ''
                          }`}>
                          Rent
                        </Button>
                        <Button
                          type="link"
                          onClick={handleGoToAbout}
                          className={`${s.menuItem} ${
                            location.pathname === '/about' ? s.active : ''
                          }`}>
                          About
                        </Button>
                        <Button
                          type="link"
                          onClick={handleGoToFaq}
                          className={`${s.menuItem} ${
                            location.pathname === '/faq' ? s.active : ''
                          }`}>
                          FAQ
                        </Button>
                        <Button
                          type="link"
                          onClick={handleGoToPricing}
                          className={`${s.menuItem} ${
                            location.pathname === '/pricing' ? s.active : ''
                          }`}>
                          Pricing
                        </Button>
                      </div>
                    )}
                    {location.pathname === '/profile' && (
                      <div className={s.leftMenu}>
                        <ul style={{ listStyle: 'none', marginTop: 32 }}>
                          <li
                            className={cn(s.profileMenuItem, {
                              [s.active]: step === 'dashboard',
                            })}>
                            <Button
                              type="link"
                              onClick={() => {
                                navigate(DASHBOARD_ROUTE);
                                dispatch(setOpenedSider(false));
                              }}>
                              Dashboard
                            </Button>
                          </li>
                          <li
                            className={cn(s.profileMenuItem, {
                              [s.active]: step === 'userProfile',
                            })}>
                            <Button
                              type="link"
                              onClick={() => {
                                handleChangeStep('userProfile');
                                dispatch(setOpenedSider(false));
                              }}>
                              User info
                            </Button>
                          </li>
                          <li
                            className={cn(s.profileMenuItem, {
                              [s.active]: step === 'favourites',
                            })}>
                            <Button
                              type="link"
                              onClick={() => {
                                handleChangeStep('favourites');
                                dispatch(setOpenedSider(false));
                              }}>
                              Favourites
                            </Button>
                          </li>
                          <li
                            className={cn(s.profileMenuItem, { [s.active]: step === 'messages' })}>
                            <Button
                              type="link"
                              onClick={() => {
                                handleChangeStep('messages');
                                dispatch(setOpenedSider(false));
                              }}>
                              Messages
                            </Button>
                          </li>
                          <li
                            className={cn(s.profileMenuItem, {
                              [s.active]: step === 'properties',
                            })}>
                            <Button
                              type="link"
                              onClick={() => {
                                handleChangeStep('properties');
                                dispatch(setOpenedSider(false));
                              }}>
                              Your postings
                            </Button>
                          </li>
                          <li
                            className={cn(s.profileMenuItem, {
                              [s.active]: step === 'notifications',
                            })}>
                            <Button
                              type="link"
                              onClick={() => {
                                handleChangeStep('notifications');
                                dispatch(setOpenedSider(false));
                              }}>
                              Notifications
                            </Button>
                          </li>
                          <li
                            className={cn(s.profileMenuItem, {
                              [s.active]: step === 'subscription',
                            })}>
                            <Button
                              type="link"
                              onClick={() => {
                                handleChangeStep('subscription');
                                dispatch(setOpenedSider(false));
                              }}>
                              Subscription
                            </Button>
                          </li>
                          <li className={s.logoutItem}>
                            <Button type="link" onClick={handleLogout}>
                              Log out
                            </Button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
