export const MY_PROPERTIES_ROUTE = '/my-properties';

export const CREATE_PROPERTY_FORM_ROUTE = `${MY_PROPERTIES_ROUTE}/create-property`;

export const EDIT_PROPERTY_FORM_ROUTE = `${MY_PROPERTIES_ROUTE}/edit-property/:propertyId`;

export const PROPERTY_DETAIL_ROUTE = `${MY_PROPERTIES_ROUTE}/detail/:propertyId`;

export const getEditPropertyRoute = (propertyId: string) =>
  EDIT_PROPERTY_FORM_ROUTE.replace(':propertyId', propertyId);

export const getPropertyDetailRoute = (propertyId: string) =>
  PROPERTY_DETAIL_ROUTE.replace(':propertyId', propertyId);
