import { FC, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { setTokenCookies } from 'tools/cookieTools';
import { Button, Input, Table } from 'ui';

import {
  useLazyGetCurrentUserQuery,
  useLazyGetUserListQuery,
  useLoginAsUserMutation,
} from 'services/user-management/userManagementApiService';

import AdminUserListAction from 'pages/admin-pages/admin-user-list/admin-user-list-action';

import SectionTitle from '../../../component/section-title/sectionTitle';
import s from './AdminUserList.module.scss';

interface AdminUserListProps {
  // Define your component props here
}

const AdminUserList: FC<AdminUserListProps> = () => {
  const [getUserList, { data: userList, isFetching }] = useLazyGetUserListQuery();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [loginAsUser] = useLoginAsUserMutation();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const handleSearch = useCallback(() => {
    getUserList(search);
  }, [getUserList, search]);

  const handleSelect = (key: string, email: string) => {
    loginAsUser({ email }).then((data) => {
      if ('data' in data) {
        setTokenCookies(data.data.accessToken);
        getCurrentUser().then((data) => {
          if ('data' in data) {
            navigate('/reports');
          }
        });
      }
    });
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'creationDate',
    },
    {
      title: 'Action',
      render: (row) => <AdminUserListAction handleSelect={(key) => handleSelect(key, row.email)} />,
    },
  ];

  return (
    <div className={s.wrapper}>
      <SectionTitle label="Users List" className={s.title} />

      <div className={s.searchBlock}>
        <Input
          name="search"
          placeholder="Name"
          size="large"
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button size="large" onClick={handleSearch}>
          Search
        </Button>
      </div>
      <div>
        <Table dataSource={userList} columns={columns} loading={isFetching} />
      </div>
    </div>
  );
};

export default AdminUserList;
