import { FC, useCallback, useMemo, useState } from 'react';

import { Button, message } from 'antd';
import cn from 'classnames';
import { Form, Input } from 'ui';

import { useChangePasswordMutation } from 'services/user-management/userManagementApiService';
import { setProfile } from 'slices/userSlice';
import { useAppDispatch } from 'store/index';

import s from './PasswordForm.module.scss';

const digitPattern = /\d/;
const uppercasePattern = /[A-Z]/;

const PasswordForm: FC = () => {
  const dispatch = useAppDispatch();

  const [passwordForm] = Form.useForm();

  const password = Form.useWatch('newPassword', passwordForm);
  const [blurPassword, setBlurPassword] = useState(false);
  const [isPasswordDirty, setIsPasswordDirty] = useState(false);
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handlePasswordChange = () => {
    setIsPasswordDirty(true);
  };

  const handlePasswordSubmit = useCallback(
    async (data) => {
      const res = await changePassword(data);

      if ('data' in res) {
        message.success('Password successfully updated!');
        dispatch(setProfile(res.data));
      }
    },
    [dispatch, changePassword],
  );

  //   useEffect(() => {
  //     if (password?.length) {
  //       setIsPasswordDirty(true);
  //     }
  //   }, [password]);

  const passwordValidation = useMemo(() => {
    return {
      length: password?.length >= 6,
      number: digitPattern.test(password),
      uppercase: uppercasePattern.test(password),
    };
  }, [password]);

  const invalidPasswordField = {
    length: (isPasswordDirty || blurPassword) && !passwordValidation.length,
    number: (isPasswordDirty || blurPassword) && !passwordValidation.number,
    uppercase: (isPasswordDirty || blurPassword) && !passwordValidation.uppercase,
  };

  return (
    <div className={s.wrapper}>
      <Form
        className={s.form}
        form={passwordForm}
        onFinish={handlePasswordSubmit}
        onValuesChange={handlePasswordChange}>
        <div className={s.row}>
          <Form.Item
            fullWidth
            name="oldPassword"
            rules={[{ required: true, message: 'Please input your old password!' }]}>
            <Input.Password
              name="oldPassword"
              size="large"
              type="password"
              placeholder="Old Password"
              // onFocus={() => setBlurPassword(false)}
            />
          </Form.Item>
        </div>
        <div className={s.row}>
          <Form.Item
            fullWidth
            name="newPassword"
            rules={[
              { required: true, message: 'Please input your new password!' },
              { min: 6, message: 'Password must be at least 6 characters long!' },
            ]}>
            <Input.Password
              name="newPassword"
              size="large"
              type="password"
              placeholder="New Password"
              // onFocus={() => setBlurPassword(false)}
              onBlur={() => setBlurPassword(true)}
            />
          </Form.Item>
        </div>
        <div className={s.row} style={{ marginTop: 12 }}>
          <div
            className={cn(
              s.hint,
              invalidPasswordField.length && s.invalid,
              isPasswordDirty && !invalidPasswordField.length && s.valid,
            )}>
            6 characters
          </div>
          <div
            className={cn(
              s.hint,
              invalidPasswordField.uppercase && s.invalid,
              isPasswordDirty && !invalidPasswordField.uppercase && s.valid,
            )}>
            Uppercase
          </div>
          <div
            className={cn(
              s.hint,
              invalidPasswordField.number && s.invalid,
              isPasswordDirty && !invalidPasswordField.number && s.valid,
            )}>
            Number
          </div>
        </div>
        <div className={s.row}>
          <Form.Item
            fullWidth
            name="confirmPassword"
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The confirm password does not match!'));
                },
              }),
            ]}>
            <Input.Password name="confirmPassword" size="large" placeholder="Confirm Password" />
          </Form.Item>
        </div>
        <div className={s.Submit}>
          <Button
            disabled={!isPasswordDirty}
            loading={isLoading}
            style={{ width: 100, height: 36, marginTop: 16 }}
            type="primary"
            htmlType="submit">
            CHANGE
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default PasswordForm;
