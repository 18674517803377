import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { setTokenCookies } from 'tools/cookieTools';

import { loginEndpoint } from 'services/user-management/userManagementApiService';

import { ProfileType, UserProfileType, UserType } from 'types/core/userTypes';

export const SLICE_NAME = 'authSlice';

export type AuthState = {
  accessToken?: UserType['accessToken'] | null;
  tokenType?: UserType['tokenType'] | null;
  expiresIn?: UserType['expiresIn'] | null;
  refreshToken?: UserType['refreshToken'] | null;
  refreshTokenExpiresIn?: UserType['refreshTokenExpiresIn'] | null;
  userProfile?: UserProfileType | null;
  profile?: ProfileType | null;
  isLoggedIn?: boolean;
};

const initialState: AuthState = {
  accessToken: null,
  tokenType: null,
  expiresIn: null,
  refreshToken: null,
  refreshTokenExpiresIn: null,
  userProfile: null,
  profile: null,
};

export const authSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => {
      return { ...state, ...action.payload };
    },
    setProfile: (state, action: PayloadAction<ProfileType | null>) => {
      state.profile = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(loginEndpoint.matchFulfilled, (state, action) => {
      state.isLoggedIn = true;
      setTokenCookies(action.payload.accessToken, action.payload.refreshToken);
    });
  },
});

export const { setAuthState, logout, setProfile } = authSlice.actions;
export default authSlice.reducer;
