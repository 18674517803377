export const MY_EVENTS_ROUTE = '/my-events';

export const CREATE_EVENT_FORM_ROUTE = `${MY_EVENTS_ROUTE}/create-event`;

export const EDIT_EVENT_FORM_ROUTE = `${MY_EVENTS_ROUTE}/edit-event/:eventId`;
export const EDIT_EVENT_PUBLISH_ROUTE = `${MY_EVENTS_ROUTE}/edit-event/:eventId?step=PUBLISH`;
export const ATTENDEES_EVENT_ROUTE = `${MY_EVENTS_ROUTE}/attendees/:eventId`;
export const ZREADING_EVENT_ROUTE = `${MY_EVENTS_ROUTE}/zreading/:eventId`;
export const EVENT_SCANNING_ROUTE = `${MY_EVENTS_ROUTE}/scanning/:eventId`;
export const EVENT_OFFER_ROUTE = `${MY_EVENTS_ROUTE}/offer/:eventId`;

export const EVENT_PERMISSION_ROUTE = `${MY_EVENTS_ROUTE}/permission/:eventId`;

export const EVENT_TAX_REPORT_ROUTE = `${MY_EVENTS_ROUTE}/tax-report/:eventId`;

export const EVENT_COUPONS_ROUTE = `${MY_EVENTS_ROUTE}/coupons/:eventId`;

export const EVENT_COUPON_CREATE_ROUTE = `${MY_EVENTS_ROUTE}/create-coupon/:eventId`;

export const EVENT_COUPON_EDIT_ROUTE = `${MY_EVENTS_ROUTE}/edit-coupon/:eventId/:couponId`;

export const EVENT_COUPON_CLONE_ROUTE = `${MY_EVENTS_ROUTE}/clone-coupon/:eventId/:couponId`;

export const getEditEventFormRoute = (eventId: string) =>
  EDIT_EVENT_FORM_ROUTE.replace(':eventId', eventId);

export const getEditEventPublishRoute = (eventId: string) =>
  EDIT_EVENT_PUBLISH_ROUTE.replace(':eventId', eventId);

export const getAttendeesRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/attendees/${eventId}`;

export const getZreadingRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/zreading/${eventId}`;

export const getScanningRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/scanning/${eventId}`;

export const getEventOfferRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/offer/${eventId}`;

export const getTaxReportRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/tax-report/${eventId}`;

export const getCouponsRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/coupons/${eventId}`;

export const getPermissionRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/permission/${eventId}`;

export const getCouponCreateRoute = (eventId: string) =>
  `${MY_EVENTS_ROUTE}/create-coupon/${eventId}`;

export const getCouponEditRoute = (eventId: string, couponId: string) =>
  `${MY_EVENTS_ROUTE}/edit-coupon/${eventId}/${couponId}`;

export const getCouponCloneRoute = (eventId: string, couponId: string) =>
  `${MY_EVENTS_ROUTE}/clone-coupon/${eventId}/${couponId}`;
