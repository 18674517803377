import { Navigate } from 'react-router-dom';

import About from 'pages/about';
import { AdminUserList } from 'pages/admin-pages';
import Calendar from 'pages/calendar';
import Dashboard from 'pages/dashboard';
import FAQ from 'pages/faq';
import Features from 'pages/features';
import Outlets from 'pages/outlets';
import PostProperty from 'pages/postProperty';
import Pricing from 'pages/pricing/pricing';
import Profile from 'pages/profile';
import PropertyDetail from 'pages/propertyDetail';
import Redirect from 'pages/redirect';
import ScanPage from 'pages/scan-page';
import Sellers from 'pages/sellers';
import ForgotPassword from 'pages/user-management/forgot-password';
import Landing from 'pages/user-management/landing';
import Login from 'pages/user-management/login';
import Register from 'pages/user-management/register';

import { RouteType } from 'types/core/routeTypes';

import AdminRoleGuard from '../component/admin-role-guard';
import { ADMIN_USERS_ROUTE } from './admin/list';
import { CALENDAR_ROUTE, OUTLETS_ROUTE, SELLERS_ROUTE } from './core/list';
import { ABOUT_ROUTE, DASHBOARD_ROUTE, FAQ_ROUTE, PRICING_ROUTE } from './dashboard/list';
import { FEATURE_ROUTE } from './help/list';
import {
  CREATE_PROPERTY_FORM_ROUTE,
  EDIT_PROPERTY_FORM_ROUTE,
  PROPERTY_DETAIL_ROUTE,
} from './property/list';
import { TICKETS_SCANS_ROUTE } from './tikets/list';
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  REDIRECT_ROUTE,
  REGISTER_ROUTE,
} from './user-management/list';
import { LANDING_ROUTE } from './user-management/list';

export const HOME_NON_AUTH_ROUTE = LOGIN_ROUTE;
export const UNKNOWN_ROUTE = '*';

export const AUTH_ROUTES: RouteType[] = [
  { path: DASHBOARD_ROUTE, element: <Dashboard /> },
  { path: ABOUT_ROUTE, element: <About /> },
  { path: PRICING_ROUTE, element: <Pricing /> },
  { path: CALENDAR_ROUTE, element: <Calendar /> },
  { path: TICKETS_SCANS_ROUTE, element: <ScanPage /> },
  { path: OUTLETS_ROUTE, element: <Outlets /> },
  { path: SELLERS_ROUTE, element: <Sellers /> },
  {
    path: ADMIN_USERS_ROUTE,
    element: (
      <AdminRoleGuard>
        <AdminUserList />
      </AdminRoleGuard>
    ),
  },
  { path: FEATURE_ROUTE, element: <Features /> },
  { path: FAQ_ROUTE, element: <FAQ /> },
  { path: CREATE_PROPERTY_FORM_ROUTE, element: <PostProperty /> },
  { path: EDIT_PROPERTY_FORM_ROUTE, element: <PostProperty /> },
  { path: PROPERTY_DETAIL_ROUTE, element: <PropertyDetail /> },
  { path: PROFILE_ROUTE, element: <Profile /> },
  { path: UNKNOWN_ROUTE, element: <Navigate to={DASHBOARD_ROUTE} /> },
];

export const NON_AUTH_ROUTES: RouteType[] = [
  { path: LANDING_ROUTE, element: <Landing /> },
  { path: LOGIN_ROUTE, element: <Login /> },
  { path: REGISTER_ROUTE, element: <Register /> },
  { path: FORGOT_PASSWORD_ROUTE, element: <ForgotPassword /> },
  { path: REDIRECT_ROUTE, element: <Redirect /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={HOME_NON_AUTH_ROUTE} /> },
];
