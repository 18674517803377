import { FC, useCallback } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { FORGOT_PASSWORD_ROUTE, LANDING_ROUTE } from 'routes/user-management/list';
import { setTokenCookies } from 'tools/cookieTools';
import { t } from 'tools/i18n';
import { Button, Checkbox, Form, Input, Label, NavLink, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { useLazyLoginQuery } from 'services/user-management/userManagementApiService';
import { setInitialView, setTerms } from 'slices/propertySlice';
import { useAppDispatch } from 'store/index';

import { EMAIL_PLACEHOLDER, PASSWORD_PLACEHOLDER } from 'constants/core-constants';

import { LOGIN_FORM_ENUM } from '../login.utils';
import s from './LoginForm.module.scss';

const LoginForm: FC = () => {
  const [getUser, { isLoading: loginIsLoading }] = useLazyLoginQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    async (data: { email: string; password: string }) => {
      dispatch(
        setTerms({
          rentOption: 'rent_sale',
          apartOption: ['all'],
          bedroomCount: -1,
          price: undefined,
          searchName: undefined,
        }),
      );
      dispatch(setInitialView(undefined));
      const res = await getUser({
        email: data.email,
        password: data.password,
        expiresInMinutes: 21600,
        refreshTokenExpiresInMinutes: 21600,
      });

      if ('data' in res) {
        setTokenCookies(res?.data?.accessToken, res?.data?.refreshToken);
        navigate(DASHBOARD_ROUTE);
      }
    },
    [getUser, dispatch, navigate],
  );

  return (
    <div className={s.wrapper}>
      <Form className={s.form} onFinish={handleSubmit} layout="vertical">
        <Link to={LANDING_ROUTE} className={s.backLink}>
          <ArrowLeftOutlined /> BACK
        </Link>
        <Typography type="h2" className={s.text}>
          Who&apos;s There?
        </Typography>

        <Form.Item
          fullWidth
          name={LOGIN_FORM_ENUM.EMAIL}
          rules={[formRules.required, formRules.email]}>
          <div>
            <Label>{t('common_email')}</Label>
            <Input name={LOGIN_FORM_ENUM.EMAIL} placeholder={EMAIL_PLACEHOLDER} size="large" />
          </div>
        </Form.Item>

        <Form.Item fullWidth name={LOGIN_FORM_ENUM.PASSWORD} rules={[formRules.required]}>
          <div>
            <Label>{t('common_password')}</Label>
            <Input.Password
              name={LOGIN_FORM_ENUM.PASSWORD}
              type={LOGIN_FORM_ENUM.PASSWORD}
              placeholder={PASSWORD_PLACEHOLDER}
              size="large"
            />
          </div>
        </Form.Item>

        <div className={s.forgotBlock}>
          <NavLink
            label={t('auth_login_forgot_password')}
            to={FORGOT_PASSWORD_ROUTE}
            className={cn(s.link, s.forgotLink)}
            typographyType="main"
          />
        </div>

        <Button htmlType="submit" fullWidth size="large" isLoading={loginIsLoading}>
          {t('auth_login_log_in')}
        </Button>
        <div className={s.checkboxes}>
          <Form.Item name="rememberMe">
            <Checkbox>Remember Me</Checkbox>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
