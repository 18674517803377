export const featureData = [
  {
    iconUrl: '/assets/images/icons/1.svg',
    title: 'Event Setup',
    items: [
      'Your Event Page',
      'Unlimited Users and Roles',
      'Hidden Events',
      'Ticket Purchase Code',
      'Pre-Launch',
      'Extra Fields for Extra Information',
      'Event Notifications',
    ],
    url: 'https://showsmanager.com/features/event-setup.html',
  },
  {
    iconUrl: '/assets/images/icons/2.svg',
    title: 'Reporting',
    items: [
      'Comprehensive Reporting Data',
      'TAX Reporting',
      'Event Day Information',
      'Summary Of Data',
      'Seller Tracking',
      'Email WebHooks',
      'Transport Reporting',
    ],
    url: 'https://showsmanager.com/features/reporting.html',
  },
  {
    iconUrl: '/assets/images/icons/7.svg',
    title: 'Ticket Scanning',
    items: [
      'Scan Tickets using any Phone with a Camera',
      'ShowsManager App',
      'Group Scanning',
      'Purchase a dedicated phone with laser scanner',
    ],
    url: 'https://showsmanager.com/features/ticket-scanning.html',
  },
  {
    iconUrl: '/assets/images/icons/4.svg',
    title: 'Event Dates',
    items: ['Single or Multi-Day Events', 'Time Slots', 'Open Daily', 'Non-Date Specific Events'],
    url: 'https://showsmanager.com/features/event-date.html',
  },
  {
    iconUrl: '/assets/images/icons/5.svg',
    title: 'Coupon Codes & Event Offers',
    items: [
      'Coupon for Multiple Uses',
      'Coupon for Single Uses',
      'Discount on Entire Order',
      'Discount on Ticket Types',
      'Multiple Show Discounts',
      'Membership Discounts',
      'Offers on specific tickets and events',
    ],
    url: 'https://showsmanager.com/features/coupon.html',
  },
  {
    iconUrl: '/assets/images/icons/6.svg',
    title: 'Seating Plans',
    items: [
      'Free Custom Seating Plans',
      '100+ pre-made Plans',
      'Flexible Seats & Section Management',
      'Combining Seating with Standing',
      'Seat Locations',
      'Different Prices for Different Dates',
      'Disable Seats',
    ],
    url: 'https://showsmanager.com/features/seating-plan.html',
  },

  {
    iconUrl: '/assets/images/icons/3.svg',
    title: 'Ticket Sales Management',
    items: [
      'Free unlimited Ticket Types',
      'Hiding & disabling ticket types',
      'Priority Pass',
      'Flexible Ticketing Options',
      'Resource Tracking',
      'Order Management',
      'Complimentary Tickets',
      'Guest List Management',
      'Ticket Offices and Resellers',
      'Your Analytics Integration',
      'Pixel Codes for Google Ads',
      'Your Affiliate Partners',
      'Sales Tracking',
      'Individual Link Tracking',
      'API for full integration',
      'Pause or End Ticket Sales',
    ],
    url: 'https://showsmanager.com/features/ticket-sales.html',
  },
];

export interface FeatureDataType {
  iconUrl: string;
  title: string;
  items: string[];
  url: string;
}
