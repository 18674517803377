import { FC } from 'react';

import { Icon, Typography } from 'ui';

import PricingCard from 'pages/pricing/pricingCard';

import s from './Subscription.module.scss';

const Subscription: FC = () => {
  return (
    <div className={s.wrapper}>
      <Typography className={s.title}>
        Your subscription: <Typography color="primary">Free</Typography>
      </Typography>
      <Typography type="main">
        Become a premium user to benefit from added features of OnThatStreet.com. Select one of our
        subscription plans to enjoy all of the tools of our product.
      </Typography>
      <div className={s.itemWrapper}>
        <div className={s.item}>
          <div className={s.iconWrapper}>
            <Icon name="clone" size={40} />
          </div>
          <Typography type="h6">
            Upload more than 1 properties. Additional properties charges at €10 per 2 months.
          </Typography>
        </div>
        <div className={s.item}>
          <div className={s.iconWrapper}>
            <Icon name="refresh" size={40} />
          </div>
          <Typography type="h6">
            View new properties. Rentals added within 30 days and sales 2 months.
          </Typography>
        </div>
        <div className={s.item}>
          <div className={s.iconWrapper}>
            <Icon name="crown" size={40} />
          </div>
          <Typography type="h6">Your properties listed with a Premium Badge</Typography>
        </div>
        <div className={s.item}>
          <div className={s.iconWrapper}>
            <Icon name="mail" size={40} />
          </div>
          <Typography type="h6">Email support</Typography>
        </div>
        <div className={s.item}>
          <div className={s.iconWrapper}>
            <Icon name="doc" size={40} />
          </div>
          <Typography type="h6">Download files and plans</Typography>
        </div>
        <div className={s.item}>
          <div className={s.iconWrapper}>
            <Icon name="star" size={40} />
          </div>
          <Typography type="h6">Be the first to use our new beta features</Typography>
        </div>
      </div>
      <div className={s.pricingCardWrapper}>
        <PricingCard period="1 Month" price={40} totalPrice={40} />
        <PricingCard period="3 Month" price={35} totalPrice={105} />
        <PricingCard period="6 Month" price={35} totalPrice={210} />
        <PricingCard period="1 Year" price={25} totalPrice={300} />
      </div>
    </div>
  );
};
export default Subscription;
