export enum SaleStatusEnum {
  Available = 'available',
  Sold = 'sold',
  UnderOffer = 'underOffer',
}

export enum PostingTypeEnum {
  Sale = 'sale',
  Rent = 'rent',
  Share = 'share',
}

export enum RentPeriodPriceTypeEnum {
  Yearly = 'Yearly',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Daily = 'Daily ',
}

export enum CommercialTypeEnum {
  Residential = 'residential',
  Commercial = 'commercial',
  Industrial = 'industrial',
}

export enum FinishStatusEnum {
  Finished = 'finished',
  PartlyFinished = 'partlyFinished',
  Shell = 'shell',
}

export enum GroundRentStatusEnum {
  Perpetual = 'perpetual',
  Limited = 'limited',
}

export enum GroundRentTypeEnum {
  FreeHold = 'freeHold',
  GroundRent = 'groundRent',
}

export enum OrientationTypeEnum {
  North = 'north',
  South = 'south',
  East = 'east',
  West = 'west',
}

export enum ResourceTypeEnum {
  PDF = 'pdf',
  Image = 'image',
}

export enum FieldDataTypeEnum {
  Number = 'number',
  String = 'string',
  Boolean = 'boolean',
}

export enum PropertyAgentRelationshipTypes {
  Agent = 'agent',
  User = ' user',
}
