import { FC } from 'react';

import { Button, Typography } from 'ui';

import s from './Pricing.module.scss';

interface PricingCardProps {
  period: string;
  price: number;
  totalPrice: number;
}

const PricingCard: FC<PricingCardProps> = ({ period, price, totalPrice }) => {
  return (
    <div className={s.pricingCard}>
      <Typography type="h4">{period}</Typography>
      <Typography type="h5">€{price} pm</Typography>
      <Typography type="label">Billed €{totalPrice} </Typography>
      <Button fullWidth>SUBSCRIBE</Button>
    </div>
  );
};
export default PricingCard;
