import { FC } from 'react';

import LandingImage from '../landing-image/LandingImage';
import s from './Register.module.scss';
import RegisterForm from './register-form';

const Register: FC = () => {
  return (
    <div className={s.wrapper}>
      <LandingImage />
      <RegisterForm />
    </div>
  );
};

export default Register;
