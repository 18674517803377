import { eventsSlice } from 'slices/eventsSlice';
import { systemSlice } from 'slices/systemSlice';

import { propertySlice } from './propertySlice';
import { authSlice } from './userSlice';

export const slices = {
  [authSlice.name]: authSlice.reducer,
  [systemSlice.name]: systemSlice.reducer,
  [eventsSlice.name]: eventsSlice.reducer,
  [propertySlice.name]: propertySlice.reducer,
};
