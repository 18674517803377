import { AuthState, SLICE_NAME } from 'slices/userSlice';
import { RootState } from 'store';

import { ProfileType } from 'types/core/userTypes';

export const userStateSelector = (state: RootState): AuthState => state[SLICE_NAME];

export const isLoggedInSelector = (state: RootState): boolean | undefined =>
  userStateSelector(state).isLoggedIn;

export const ProfileSelector = (state: RootState): ProfileType | null | undefined =>
  userStateSelector(state).profile;
