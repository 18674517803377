import { RootState } from 'store';

import {
  GetPropertyType,
  InitialViewType,
  PropertyType,
  TermsType,
} from 'types/property/propertyTypes';

export const propertySliceSelector = (state: RootState) => state.propertySlice;

export const createActivePropertySelector = (state: RootState): PropertyType | undefined =>
  propertySliceSelector(state)?.activeProperty;

export const propertyListSelector = (state: RootState): GetPropertyType[] | undefined =>
  propertySliceSelector(state)?.propertyList;

export const initialViewSelector = (state: RootState): InitialViewType | undefined =>
  propertySliceSelector(state)?.initialView;

export const termsSelector = (state: RootState): TermsType | undefined =>
  propertySliceSelector(state)?.terms;
