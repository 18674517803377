import React, { FC, useCallback, useEffect } from 'react';

import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ABOUT_ROUTE, DASHBOARD_ROUTE, FAQ_ROUTE, PRICING_ROUTE } from 'routes/dashboard/list';
import { CREATE_PROPERTY_FORM_ROUTE } from 'routes/property/list';
import { PROFILE_ROUTE } from 'routes/user-management/list';
import 'tools/hooks/debounce.hook';
import { Icon } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActiveProperty } from 'slices/propertySlice';
import { setProfile } from 'slices/userSlice';
import { useAppDispatch } from 'store/index';

import s from './NavBar.module.scss';
import Filters from './filters';

interface NavBarProps {
  initiallyLogged: boolean;
}

const NavBar: FC<NavBarProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [getCurrentuser, { data: profile }] = useLazyGetCurrentUserQuery();
  const handleGoToProfile = useCallback(() => {
    navigate(`${PROFILE_ROUTE}?step=userProfile`);
  }, [navigate]);

  const goToDashboard = useCallback(() => {
    navigate(DASHBOARD_ROUTE);
  }, [navigate]);

  const handleGoToAbout = useCallback(() => {
    navigate(ABOUT_ROUTE);
  }, [navigate]);

  const handleGoToFaq = useCallback(() => {
    navigate(FAQ_ROUTE);
  }, [navigate]);

  const handleGoToPricing = useCallback(() => {
    navigate(PRICING_ROUTE);
  }, [navigate]);

  const goToProperty = useCallback(() => {
    dispatch(setActiveProperty(undefined));
    navigate(CREATE_PROPERTY_FORM_ROUTE);
  }, [navigate, dispatch]);

  useEffect(() => {
    getCurrentuser();
  }, [getCurrentuser]);

  useEffect(() => {
    if (profile) {
      dispatch(setProfile(profile));
    }
  }, [profile, dispatch]);

  return (
    <div
      className={`${s.entireWrapper} ${
        location.pathname === '/dashboard' ? s.dashboardNavbar : ''
      }`}>
      <div className={s.navWrapper}>
        <Button style={{ paddingLeft: 0 }} type="link" onClick={goToDashboard}>
          <Icon width={200} name="logo" />
        </Button>
        <div className={s.menu}>
          <Button
            type="link"
            className={`${s.menuItem} ${location.pathname === '/buy' ? s.active : ''}`}>
            Buy
          </Button>
          <Button
            type="link"
            className={`${s.menuItem} ${location.pathname === '/rent' ? s.active : ''}`}>
            Rent
          </Button>
          <Button
            type="link"
            onClick={handleGoToAbout}
            className={`${s.menuItem} ${location.pathname === '/about' ? s.active : ''}`}>
            About
          </Button>
          <Button
            type="link"
            onClick={handleGoToFaq}
            className={`${s.menuItem} ${location.pathname === '/faq' ? s.active : ''}`}>
            FAQ
          </Button>
          <Button
            type="link"
            onClick={handleGoToPricing}
            className={`${s.menuItem} ${location.pathname === '/pricing' ? s.active : ''}`}>
            Pricing
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Button className={s.post} onClick={goToProperty}>
            POST FOR FREE
          </Button>
          <Button onClick={handleGoToProfile} className={s.actions}>
            <div className={s.profile}>
              {profile?.profileImageURL &&
                profile?.profileImageURL !==
                  'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/' && (
                  <div className={s.uploadedFile}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <img
                        className={s.uploadedImage}
                        src={profile?.profileImageURL}
                        width={24}
                        height={24}
                        alt="promotionMedia"
                      />
                    </div>
                  </div>
                )}
              {!profile?.profileImageURL ||
                (profile?.profileImageURL ===
                  'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/' && (
                  <div className={s.uploadedFile}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <img
                        className={s.uploadedImage}
                        src="/assets/images/man.png"
                        width={24}
                        height={24}
                        alt="promotionMedia"
                      />
                    </div>
                  </div>
                ))}
            </div>
            <span className={s.text}>MY PROFILE</span>
          </Button>
        </div>
      </div>
      <Filters />
    </div>
  );
};
export default NavBar;
