import { FC, useCallback, useState } from 'react';

import { Dropdown, Icon } from 'ui';

import s from './AdminUserListAction.module.scss';

const DROPDOWN_ITEMS = () => [
  { key: 'audits', label: <div className={s.dropdownItem}>Audits</div> },
  { key: 'resetPsword', label: <div className={s.dropdownItem}>Reset Password</div> },
  { key: 'loginAs', label: <div className={s.dropdownItem}>Login as</div> },
  { key: 'showDialog', label: <div className={s.dropdownItem}>Show cash in dialog</div> },
];

interface AdminUserListActionProps {
  handleSelect: (key: string) => void;
}

const AdminUserListAction: FC<AdminUserListActionProps> = (props) => {
  const { handleSelect } = props;
  const [openedDropdown, setOpenedDropdown] = useState(false);

  const handleItemClick = useCallback(
    (ev: { key: string }) => {
      handleSelect(ev.key);
    },
    [handleSelect],
  );

  return (
    <Dropdown
      items={DROPDOWN_ITEMS()}
      placement="bottomRight"
      open={openedDropdown}
      onOpenChange={setOpenedDropdown}
      handleItemClick={handleItemClick}>
      <div className={s.dropdownButton}>
        <Icon name="verticalDots" />
      </div>
    </Dropdown>
  );
};

export default AdminUserListAction;
