import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { SyncOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import 'mapbox-gl/dist/mapbox-gl.css';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Map, {
  FullscreenControl,
  GeolocateControl,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from 'react-map-gl';
import { Icon, Typography } from 'ui';
import PropertyCard from 'ui/propertyCard';
import PropertyTableCard from 'ui/propertyTableCard';

import { initialViewSelector, termsSelector } from 'selectors/propertySlice.selector';
import { useAppSelector } from 'store/index';

import { PostingTypeEnum } from 'types/property/propertyEnum';
import { GetPropertyType } from 'types/property/propertyTypes';

// Import Mapbox CSS
import s from './Map.module.scss';
import useDebounceMap from './Map.util';

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN;

interface MapComponentProps {
  properties: GetPropertyType[];
}

const MapComponent: FC<MapComponentProps> = ({ properties }) => {
  const [showMapStyle, setShowMapStyle] = useState('mapbox://styles/mapbox/streets-v12');
  const [popupInfo, setPopupInfo] = useState<GetPropertyType | null>(null);
  const [showList, setShowList] = useState<boolean>(true);
  const [visibleMarkers, setVisibleMarkers] = useState<GetPropertyType[]>(properties);
  const terms = useAppSelector(termsSelector);
  const mapRef = useRef<MapRef>(null);
  const initialView = useAppSelector(initialViewSelector);
  const [activeId, setActiveId] = useState<string>();
  const [placeId, setPlaceId] = useState<string>();

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const handleClick = useCallback(
    (item) => {
      setActiveId(item.id);
      setPopupInfo(null);
      if (mapRef.current) {
        mapRef.current.flyTo({
          center: [item.coordinates.longitude, item.coordinates.latitude],
          duration: 1000,
          zoom: 13,
          essential: true,
        });
      }
    },
    [setActiveId, mapRef],
  );

  const toggleMapStyle = () => {
    // Toggle between satellite and street view styles
    const newMapStyle =
      showMapStyle === 'mapbox://styles/mapbox/streets-v12'
        ? 'mapbox://styles/mapbox/satellite-streets-v12'
        : 'mapbox://styles/mapbox/streets-v12';

    setShowMapStyle(newMapStyle);
  };

  const formatPrice = (value: number): string => {
    if (value >= 1_000_000) {
      return `€${(value / 1_000_000).toFixed(1)} M`;
    } else if (value >= 1_000) {
      return `€${(value / 1_000).toFixed(1)} K`;
    } else {
      return `€${value}`;
    }
  };

  const getVisibleMarkers = useCallback(() => {
    if (!mapRef.current) return;

    const bounds = mapRef.current.getBounds();
    const [sw, ne] = [bounds?.getSouthWest(), bounds?.getNorthEast()];

    const filteredMarkers = properties.filter((marker) => {
      return (
        marker?.coordinates?.latitude >= sw!.lat &&
        marker?.coordinates?.latitude <= ne!.lat &&
        marker?.coordinates?.longitude >= sw!.lng &&
        marker?.coordinates?.longitude <= ne!.lng
      );
    });

    setVisibleMarkers(filteredMarkers); // Update state
  }, [properties]);

  const debouncedGetVisibleMarkers = useDebounceMap(getVisibleMarkers, 300);

  React.useEffect(() => {
    getVisibleMarkers(); // Initial calculation
  }, [properties, getVisibleMarkers]);

  const pins = properties
    ?.filter((item) => item.coordinates.latitude !== 0 && item.coordinates.longitude)
    .map((item, index) => {
      return (
        <Marker
          key={`marker-${index}`}
          longitude={item.coordinates.longitude}
          latitude={item.coordinates.latitude}
          anchor="bottom"
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setPopupInfo(item);
            if (mapRef.current) {
              mapRef.current.flyTo({
                center: [item.coordinates.longitude - 0.002, item.coordinates.latitude - 0.003],
                duration: 1000,
                zoom: 13,
                essential: true,
              });
            }
          }}>
          <div className={s.marker}>
            <div className={s.pricePanel}>{formatPrice(item.price)}</div>
            {item?.watched ? (
              <Icon
                className={classNames(s.icon, {
                  [s.animated]: activeId === item.id && showList === true,
                })}
                size={32}
                color={
                  item?.postingType === PostingTypeEnum.Rent
                    ? '#02C29A'
                    : item?.postingType === PostingTypeEnum.Share
                    ? '#187DFD'
                    : '#8E53E8'
                }
                name={popupInfo?.id === item?.id ? 'selectedFavPin' : 'favPin'}
              />
            ) : (
              <Icon
                className={classNames(s.icon, {
                  [s.animated]: activeId === item.id && showList === true,
                })}
                size={32}
                color={
                  item?.postingType === PostingTypeEnum.Rent
                    ? '#02C29A'
                    : item?.postingType === PostingTypeEnum.Share
                    ? '#187DFD'
                    : '#8E53E8'
                }
                name={popupInfo?.id === item?.id ? 'selectedPin' : 'pin'}
              />
            )}
          </div>
        </Marker>
      );
    });

  useEffect(() => {
    if (terms?.searchName) {
      getPlacePredictions({
        input: terms?.searchName,
        componentRestrictions: { country: 'mt' },
      });
    }
  }, [terms?.searchName, getPlacePredictions]);

  useEffect(() => {
    if (placePredictions.length > 0 && !isPlacePredictionsLoading) {
      const placeId = placePredictions[0]?.place_id;
      setPlaceId(placeId);
    }
  }, [placePredictions, isPlacePredictionsLoading]);

  useEffect(() => {
    if (placeId && placesService) {
      placesService.getDetails({ placeId }, (placeDetails) => {
        const lat = placeDetails.geometry.location.lat();
        const lng = placeDetails.geometry.location.lng();

        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [lng, lat],
            zoom: 16,
            duration: 2000,
            essential: true,
          });
        }
      });
    }
  }, [placeId, placesService, mapRef]);

  return (
    <div className={s.mapWrapper}>
      <Button className={s.changeButton} onClick={toggleMapStyle}>
        <SyncOutlined />
      </Button>
      {showList && visibleMarkers && (
        <div className={s.propertyList}>
          <div className={s.propertyListHeader}>
            <Typography type="main" className={s.notification} weight={700}>
              {visibleMarkers?.length} PROPERTIES FOUND
            </Typography>
            <Button type="link" onClick={() => setShowList(false)}>
              <Icon name="close" size={20} color="grey" />
            </Button>
          </div>
          <div className={s.propertyContents}>
            {visibleMarkers?.map((item, index) => {
              return (
                <PropertyTableCard
                  key={index}
                  isActive={activeId === item.id}
                  onClick={() => handleClick(item)}
                  property={item}
                />
              );
            })}
          </div>
        </div>
      )}
      {!showList && (
        <Button className={s.showList} onClick={() => setShowList(!showList)}>
          <UnorderedListOutlined /> Show Properties in List
        </Button>
      )}
      <Map
        ref={mapRef}
        initialViewState={
          initialView
            ? initialView
            : {
                longitude: 14.5146, // Center the map around Malta's coordinates
                latitude: 35.8989,
                zoom: 12,
                bearing: 0,
                pitch: 0,
              }
        }
        onZoom={debouncedGetVisibleMarkers}
        onMove={debouncedGetVisibleMarkers}
        mapStyle={showMapStyle}
        mapboxAccessToken={MAPBOX_TOKEN}>
        <GeolocateControl position="bottom-right" />
        <FullscreenControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        <ScaleControl />

        {pins}

        {popupInfo && (
          <Popup
            className="z-50"
            anchor="top"
            offset={[-32, 32]}
            longitude={Number(popupInfo.coordinates.longitude)}
            latitude={Number(popupInfo.coordinates.latitude)}
            onClose={() => setPopupInfo(null)}>
            <PropertyCard editable={false} property={popupInfo} />
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default MapComponent;
