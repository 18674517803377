import { FC } from 'react';

import { Icon, Typography } from 'ui';

import s from './About.module.scss';

const About: FC = () => {
  return (
    <div className={s.wrapper}>
      <Typography type="h1" weight={900}>
        About
      </Typography>

      <img src="/assets/images/about.webp" className={s.img} alt="about" />

      <div className={s.content}>
        <Typography type="h4" className={s.comment} weight={500}>
          Onthatstreet is a platform that allows property owners to advertise the location and price
          of their property on our interactive map of Malta. For sale, for rent or shared rentals.
        </Typography>
        <div style={{ marginTop: 48 }}>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="person" color="#8E53E8" size={40} />
            </div>
            <Typography type="h6">
              Property owners can associate agents with their listing who can be contacted directly
              by people who browse our website or add their own contact details
            </Typography>
          </div>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="refresh" size={40} />
            </div>
            <Typography type="h6">
              When a property is sold or rented it is immediately taken off the website and all
              agents are notified
            </Typography>
          </div>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="heart" color="#8E53E8" size={40} />
            </div>
            <Typography type="h6">
              Posting a property is free and onthatstreet does not get any part of the sale or
              rental fees. Property owners, agents, purchasers and tentants are free to deal between
              themselves
            </Typography>
          </div>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="star" size={40} />
            </div>
            <Typography type="h6">
              Onthatstreet premium members are able to post 2 or more properties. Pricing is for
              memberships is found here
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
