import { FC, useEffect } from 'react';

import { Spin } from 'antd';
import classNames from 'classnames';
import { Typography } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { useGetPropertiesMutation } from 'services/properties/propertyApiService';

import s from './Properties.module.scss';

const Properties: FC = () => {
  const [getProperties, { isLoading, data }] = useGetPropertiesMutation();

  useEffect(() => {
    const criteria = {
      pageNumber: 1,
      pageSize: 100,
    };
    getProperties(criteria);
  }, [getProperties]);

  return (
    <div className={s.wrapper}>
      <Typography>Your properties</Typography>
      {isLoading && (
        <div className={s.loadingWrapper}>
          <Spin size="large" style={{ color: '#8E53E8', fontSize: 64 }} />
        </div>
      )}
      {data && (
        <div
          className={classNames(s.components, {
            [s['more-than-4']]:
              data!.properties?.filter((item) => item.isEditable === true).length > 4,
          })}>
          {data?.properties
            .filter((item) => item.isEditable === true)
            .map((item, index) => (
              <PropertyCard key={index} property={item} editable={true} />
            ))}
        </div>
      )}
    </div>
  );
};
export default Properties;
