export type UserRoleType = {
  roleCode: string;
  roleName: string;
};

export type PromoterType = {
  promoterId: string;
  name: string;
  slug: string;
  promoterShowsHappeningPageURL: string;
  logo: string;
  email: string;
};

export type UserEventType = {
  eventFlyer: string;
  eventName: string;
  eventId: string;
  lastShowDate: string;
  eventScheduleType: string;
  promoter: PromoterType;
  permissions: string[];
  currency: string;
};

export type PreferredUserCategoriesType = {
  name: string;
  id: 0;
};

export type PreferredUserTagType = {
  name: string;
  id: string;
};

export type UserProfileType = {
  firstName: string;
  lastName: string;
  userId: string;
  email: string;
  fullName: string;
  mobileNumber: string;
  street1: string;
  street2: string;
  town: string;
  gender: string;
  countryName: string;
  optInForPromoterPromotionalMaterial: boolean;
  optInForShowsHappeningPromotionalMaterial: boolean;
  dateOfBirth: string;
  dateOfBirthRange: string;
  hasEvents: boolean;
  profileImage: string;
  country: string;
  isManager: boolean;
  roles?: UserRoleType[];
  promoters: PromoterType[];
  events?: UserEventType[];
  permissions?: string[];
  preferredCategories?: PreferredUserCategoriesType[];
  preferredTags?: PreferredUserTagType[];
};

export type UserType = {
  accessToken: string;
  tokenType: string;
  expiresOn: string;
  expiresIn: number;
  refreshTokenExpiresOn: string;
  refreshTokenExpiresIn: number;
  refreshToken: string;
  userProfile: UserProfileType;
};

export type ProfileType = {
  email?: string;
  firstName: string;
  lastName: string;
  propertiesManaged: string;
  contactDetailsPrivate: boolean;
  mobileNumber: string;
  userType: USER_TYPE_ENUM;
  profileImageURL?: string;
  activationStatus?: 'Registered' | 'Confirmed';
  mobilePhoneActivationStatus?: 'NotConfirmed' | 'Confirmed';
  id: string; // UUID format
  roles?: {
    roleCode: string;
    roleName: string;
  }[];
};

export enum USER_TYPE_ENUM {
  USER = 'user',
  AGENT = 'agent',
  DEVELOPER = 'developer',
}

export enum CONTACT_VISIBILITY {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export type UserProfileImageType = {
  fileName: string;
  base64Image: string;
};

export type UserUpdateReqType = {
  firstName: string;
  lastName: string;
  propertiesManaged: string;
  mobileNumber: string;
  userType: USER_TYPE_ENUM;
};
