import React, { FC } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Typography } from 'ui';

import s from './Features.module.scss';

interface FeatureCardProps {
  iconUrl: string;
  title: string;
  items: string[];
  url: string;
}

const FeatureCard: FC<FeatureCardProps> = (props) => {
  const { iconUrl, title, items, url } = props;

  const handleButtonClick = () => {
    window.open(url, '_blank');
  };
  return (
    <div className={s.featureWrapper}>
      <img src={iconUrl} alt="featureImg" width={60} />
      <Typography type="h2">{title}</Typography>
      <ul style={{ marginLeft: '2rem' }}>
        {items &&
          items.map((item, index) => (
            <li key={index}>
              <Typography type="h6">{item}</Typography>
            </li>
          ))}
      </ul>
      <Button className={s.eventButton} onClick={handleButtonClick}>
        DO MORE
        <ArrowRightOutlined />
      </Button>
    </div>
  );
};

export default FeatureCard;
