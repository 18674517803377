import { FC, memo } from 'react';

import { Table as TableAntd, TableProps as TablePropsAntd } from 'antd';
import 'antd/lib/table/style/index';

import './Table.module.scss';

// eslint-disable-next-line
export type TableProps<RecordType extends Record<any, any> = any> = TablePropsAntd<RecordType>;

const Table: FC<TableProps> = (props) => {
  const { loading, ...rest } = props;
  return <TableAntd loading={loading} {...rest} pagination={false} />;
};

// export type { TTableProps }

export default memo(Table);
