export const handleCutString = (
  str: string,
  size: number = 30,
  dotsEnd?: boolean,
  endLength: number = 5,
): string => {
  let value = String(str);

  if (value.length >= size && !dotsEnd)
    value = `${value.slice(0, size - endLength)}...${value.slice(-(endLength - 1))}`;

  if (value.length >= size && dotsEnd) value = `${value.slice(0, size)}...`;

  return value;
};
