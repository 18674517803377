import { FC } from 'react';

import { PhoneFilled } from '@ant-design/icons';
import { Typography } from 'ui';

import s from './AgentCard.module.scss';

interface AgentCardProps {
  userId?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  profileImageURL?: string;
  showMobile?: boolean;
}

const AgentCard: FC<AgentCardProps> = ({
  firstName,
  lastName,
  mobileNumber,
  profileImageURL,
  showMobile,
}) => {
  return (
    <div className={s.wrapper}>
      <div className={s.imageWrapper}>
        <img
          src={profileImageURL ? profileImageURL : '/assets/images/avatar/avatar.svg'}
          width={50}
          height={50}
          style={{ borderRadius: '50%' }}
          alt="agent"
        />
        <div>
          <Typography type="h4">
            {firstName} {lastName}
          </Typography>
          <Typography type="label"> Agency</Typography>
        </div>
      </div>
      <div className={s.infoWrapper}>
        <div className={s.agenciesImage}>
          <PhoneFilled style={{ fontSize: 20, color: '#CED2E5' }} />
          {!showMobile && (
            <Typography type="h6" weight={700}>
              {mobileNumber}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
export default AgentCard;
