import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { HeartOutlined, StarOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Skeleton } from 'antd';
// import ImageGallery from 'react-image-gallery';
import Map, {
  FullscreenControl,
  GeolocateControl,
  MapRef,
  Marker,
  NavigationControl,
  ScaleControl,
} from 'react-map-gl';
import { useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { Icon, Label, Typography, message } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { propertyListSelector, termsSelector } from 'selectors/propertySlice.selector';
import {
  useGetPropertiesMutation,
  useLazyGetPropertyAgenciesQuery,
  useLazyGetPropertyDetailQuery,
  useWatchPropertyMutation,
} from 'services/properties/propertyApiService';
import { setPropertyList } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { PostingTypeEnum } from 'types/property/propertyEnum';
import { GetPropertyType, getPropertyAgenciesResType } from 'types/property/propertyTypes';

import '../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss';
import s from './PropertyDetail.module.scss';
import AgentCard from './agentCard';
import ContactCard from './contactCard';
import ImageGrid from './imageGrid';
import OwnerCard from './ownerCard';

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN;

const PropertyDetail: FC = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mapRef = useRef<MapRef>(null);
  const propertyList = useAppSelector(propertyListSelector);
  const [watchProperty, { isLoading: watching }] = useWatchPropertyMutation();
  const [getPropertyAgencies] = useLazyGetPropertyAgenciesQuery();
  const [getProperties] = useGetPropertiesMutation();
  const terms = useAppSelector(termsSelector);
  const [getPropertyDetail, { data: PropertyDetail, isFetching }] = useLazyGetPropertyDetailQuery();
  const [currentProperty, setCurrentProperty] = useState<GetPropertyType>();
  const [isWatched, setIsWatched] = useState<boolean>(false);
  const [agenciesList, setAgenciesList] = useState<getPropertyAgenciesResType>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [images, setImages] = useState<
    {
      original: string;
      thumbnail: string;
      originalHeight?: number;
      originalWidth?: number;
    }[]
  >();

  const goToDashboard = useCallback(() => {
    navigate(DASHBOARD_ROUTE);
  }, [navigate]);

  const formatCurrency = (value) => {
    return `€${value?.toLocaleString()}`;
  };

  const [showMapStyle, setShowMapStyle] = useState('mapbox://styles/mapbox/streets-v12');

  const toggleMapStyle = () => {
    // Toggle between satellite and street view styles
    const newMapStyle =
      showMapStyle === 'mapbox://styles/mapbox/streets-v12'
        ? 'mapbox://styles/mapbox/satellite-streets-v12'
        : 'mapbox://styles/mapbox/streets-v12';

    setShowMapStyle(newMapStyle);
  };

  useEffect(() => {
    if (propertyId) {
      getPropertyDetail(propertyId);
    }
  }, [getPropertyDetail, propertyId]);

  useEffect(() => {
    const temp = propertyList?.find((item) => item.id === propertyId);
    if (temp) {
      if (temp?.watched) {
        setIsWatched(true);
      }
      setCurrentProperty(temp);
    }
  }, [propertyId, propertyList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPropertyAgencies(); // Optional: use unwrap to access data or handle errors
        if ('data' in res) {
          setAgenciesList(res.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, [getPropertyAgencies]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [currentProperty?.coordinates.longitude!, currentProperty?.coordinates.latitude!],
        zoom: 16,
        duration: 1000,
        essential: true,
      });
    }
  }, [currentProperty]);

  useEffect(() => {
    if (PropertyDetail) {
      const tempImages = PropertyDetail?.images!.map((item) => ({
        original: item?.imageURL ? item?.imageURL : '/assets/images/no-image.svg',
        thumbnail: item?.imageURL ? item?.imageURL : '/assets/images/no-image.svg',
      }));

      setImages(tempImages);
    }
  }, [PropertyDetail]);

  const handleWatchProperty = async (id: string) => {
    const res = await watchProperty(id!);

    if ('data' in res) {
      message.success('Added to favourites');
      if (terms) {
        const criteria = {
          pageNumber: 1,
          pageSize: 100,
          filters: {
            numberOfBedrooms: terms.bedroomCount === -1 ? undefined : terms.bedroomCount,
            town: terms.searchName,
            priceFrom: terms.moreFilters?.from,
            priceTo: terms.moreFilters?.to,
            propertyTypes: terms.apartOption?.includes('all') ? undefined : terms.apartOption,
            postingType:
              terms.rentOption === 'rent_sale' ? undefined : (terms.rentOption as PostingTypeEnum),
          },
        };
        const finalRes = await getProperties(criteria);
        if ('data' in finalRes) {
          dispatch(setPropertyList(finalRes.data.properties as GetPropertyType[]));
        }
      }
    }
  };

  return (
    <div className={s.wrapper}>
      <Typography type="h2">{currentProperty?.name}</Typography>
      <Label className={s.Label}>{currentProperty?.address?.street}</Label>
      <div className={s.buttonGroup}>
        <Button type="link" className={s.linkButton} onClick={goToDashboard}>
          <Icon name="map" /> SHOW ON MAP
        </Button>
        <Button type="link" className={s.linkButton} onClick={showModal}>
          <UploadOutlined style={{ fontSize: 20 }} /> SHARE PAGE
        </Button>
        {!isWatched && (
          <Button
            type="link"
            className={s.linkButton}
            loading={watching}
            onClick={() => handleWatchProperty(propertyId!)}>
            <HeartOutlined style={{ fontSize: 20 }} /> SAVE TO FAVOURITES
          </Button>
        )}
      </div>
      <div className={s.content}>
        <div className={s.imageGallery}>
          {/* {images && <ImageGallery items={images} />} */}
          {isFetching ? (
            <div className={s.formBlock}>
              <Skeleton loading={isFetching} active paragraph={{ rows: 6 }}></Skeleton>
            </div>
          ) : (
            images && <ImageGrid images={images} />
          )}
          <div className={s.field}>
            <Typography className={s.fieldTitle}>About</Typography>
            <Typography type="main">{PropertyDetail?.comments}</Typography>
          </div>
          <div className={s.field}>
            {PropertyDetail?.parameters && (
              <Typography className={s.fieldTitle}>Parameters</Typography>
            )}
            {PropertyDetail?.parameters &&
              PropertyDetail?.parameters.map((item, index) => (
                <div key={index} className={s.parameter}>
                  <Typography className={s.parameterlabel}>{item.name}</Typography>
                  <Typography type="h6">{item.value}</Typography>
                </div>
              ))}
          </div>
          <div className={s.field}>
            {PropertyDetail?.specialFeatures && (
              <Typography className={s.fieldTitle}>Special characteristics</Typography>
            )}
            <div className={s.specialParameter}>
              {PropertyDetail?.specialFeatures &&
                PropertyDetail?.specialFeatures.map((item, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <StarOutlined style={{ fontSize: 20 }} />
                    <Typography className={s.parameterlabel}>{item.name}</Typography>
                  </div>
                ))}
            </div>
          </div>
          <div className={s.field}>
            {PropertyDetail?.resources?.length! > 0 && (
              <Typography className={s.fieldTitle}>Files</Typography>
            )}
            <div className={s.specialParameter}>
              {PropertyDetail?.resources &&
                PropertyDetail?.resources.map((item, index) => (
                  <div key={index} className={s.item}>
                    <div className={s.iconWrapper}>
                      <Icon name="doc" size={32} />
                    </div>
                    <a href={item.url} download={item.name} className={s.link}>
                      <Typography type="h6">{item.name}</Typography>
                    </a>
                  </div>
                ))}
            </div>
          </div>
          <div className={s.field}>
            {currentProperty?.coordinates && <Typography className={s.fieldTitle}>Map</Typography>}
            {currentProperty?.coordinates && (
              <>
                <div className={s.infoLocation}>
                  <div className={s.mapWrapper}>
                    <Button className={s.changeButton} onClick={toggleMapStyle}>
                      <SyncOutlined />
                    </Button>
                    <Map
                      ref={mapRef}
                      initialViewState={{
                        longitude: currentProperty?.coordinates?.longitude || 14.5146,
                        latitude: currentProperty?.coordinates?.latitude || 35.8989,
                        zoom: 16,
                        bearing: 0,
                        pitch: 0,
                      }}
                      mapStyle={showMapStyle}
                      mapboxAccessToken={MAPBOX_TOKEN}>
                      <GeolocateControl position="bottom-right" />
                      <FullscreenControl position="bottom-right" />
                      <NavigationControl position="bottom-right" />
                      <ScaleControl />

                      <Marker
                        longitude={currentProperty?.coordinates?.longitude || 14.5146}
                        latitude={currentProperty?.coordinates?.latitude || 35.8989}
                        // longitude={14.5146} // Center the map around Malta's coordinates
                        // latitude={35.8989}
                        anchor="bottom"></Marker>
                    </Map>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={s.field}>
            <Typography className={s.fieldTitle}>Could be interesting</Typography>
            <div className={s.interesting}>
              {propertyList
                ?.filter(
                  (property) =>
                    property.coordinates.latitude !== 0 && property.coordinates.longitude !== 0,
                )
                .map((property, index) => (
                  <PropertyCard editable={false} key={index} property={property} />
                ))}
            </div>
          </div>
        </div>
        {PropertyDetail && (
          <div className={s.agent}>
            <OwnerCard
              firstName={PropertyDetail?.owner?.firstName}
              lastName={PropertyDetail?.owner?.lastName}
              mobileNumber={PropertyDetail?.owner?.mobileNumber}
              profileImageURL={PropertyDetail?.owner?.profileImageURL}
              hideMobile={
                PropertyDetail?.owner?.contactDetailsPrivate && !PropertyDetail?.isEditable
              }
            />

            {agenciesList?.length! > 0 &&
              agenciesList?.map((agency, index) => (
                <AgentCard
                  key={index}
                  firstName={agency?.name}
                  mobileNumber={agency?.mobileNumber}
                  profileImageURL={agency?.profilePicture}
                />
              ))}
            <ContactCard
              firstName={PropertyDetail?.owner?.firstName}
              lastName={PropertyDetail?.owner?.lastName}
              profileImageURL={PropertyDetail?.owner?.profileImageURL}
              price={PropertyDetail?.price}
              agenciesList={agenciesList}
              isAgency={agenciesList?.length! > 0}
            />
          </div>
        )}
      </div>
      <Modal
        width={600}
        centered
        title={<div style={{ fontSize: 24, fontWeight: 700 }}>Share this page</div>}
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        <div className={s.modalWrapper}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <img
              src={PropertyDetail?.images![0].imageURL}
              alt="Share info"
              width={64}
              height={64}
              style={{ borderRadius: 6 }}
            />
            <span className={s.modalComment}>
              {PropertyDetail?.name} {formatCurrency(PropertyDetail?.price)}{' '}
              {currentProperty?.featuredParameters
                .map((param) => `${param.value} ${param.shortenedCaption}`)
                .join(' • ')}{' '}
              • {currentProperty?.totalSize}м² {PropertyDetail?.address?.street}{' '}
            </span>
          </div>
          <div className={s.shareButtonGroup}>
            <Button className={s.shareButton}>
              <img src="/assets/images/facebook.png" alt="facebook" width={32} height={32} />
              Facebook
            </Button>
            <Button className={s.shareButton}>
              <img src="/assets/images/messenger.png" alt="messenger" width={32} height={32} />
              Messenger
            </Button>
            <Button className={s.shareButton}>
              <img src="/assets/images/whatsapp.png" alt="whatsapp" width={32} height={32} />
              WhatsApp
            </Button>
            <Button className={s.shareButton}>
              <div className={s.shareIconWrapper}>
                <Icon name="clone" size={24} />
              </div>
              Copy Link
            </Button>
            <Button className={s.shareButton}>
              <div className={s.shareIconWrapper}>
                <Icon name="mail" size={24} />
              </div>
              E-mail
            </Button>
            <Button className={s.shareButton}>
              <div className={s.shareIconWrapper}>
                <Icon name="doc" size={24} />
              </div>
              Embed Code
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PropertyDetail;
