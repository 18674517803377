import React from 'react';

import { Button, Typography } from 'antd';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { LOGIN_ROUTE, REGISTER_ROUTE } from 'routes/user-management/list';
import { Icon } from 'ui';

import LandingImage from '../landing-image/LandingImage';
import s from './Landing.module.scss';

const { Title } = Typography;

const Landing = () => {
  return (
    <div className={s.landingWrapper}>
      <LandingImage />
      <div className={s.content}>
        <Icon name="logo" className={s.logo} />
        <Title className={s.landingTitle}>Find your place</Title>
        <p className={s.subTitle}>
          Location and price of properties on our interactive map of Malta.
        </p>
        <ul style={{ marginLeft: 24 }}>
          <li className={s.list}>List for free</li>
          <li className={s.list}>For property owners, seekers and agents.</li>
          <li className={s.list}>First users will get discounted premium accounts.</li>
        </ul>
        <div className={s.buttonGroup}>
          <RouterNavLink style={{ width: '100%' }} to={REGISTER_ROUTE}>
            <Button className={s.firstButton}>SIGN UP</Button>
          </RouterNavLink>
          <RouterNavLink style={{ width: '100%' }} to={LOGIN_ROUTE}>
            <Button className={s.secondButton}>KNOCK KNOCK</Button>
          </RouterNavLink>
        </div>
      </div>
    </div>
  );
};

export default Landing;
