import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import initialApiService from './services/initialApiService';
import { rtkQueryErrorLogger } from 'services/errorHandler';

import { slices } from './slices';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['propertySlice'], // List of slices you want to persist
};

const rootReducer = combineReducers({
  [initialApiService.reducerPath]: initialApiService.reducer,
  ...slices,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Ignore state path that persistReducer adds
        ignoredPaths: ['_persist'],
      },
    }).concat(initialApiService.middleware, rtkQueryErrorLogger);
  },
});

// export const store = configureStore({
//   reducer: {
//     [initialApiService.reducerPath]: initialApiService.reducer,
//     ...slices,
//   },
//   middleware: (getDefaultMiddleware) => {
//     return getDefaultMiddleware({ serializableCheck: false }).concat(
//       initialApiService.middleware,
//       rtkQueryErrorLogger,
//     );
//   },
// });

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// use these functions instead of useDispatch and useSelector
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
