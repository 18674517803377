import React from 'react';

import s from './LandingImage.module.scss';

const LandingImage = () => {
  return (
    <div className={s.image}>
      <img src="/assets/images/landing.webp" alt="featureImg" />
    </div>
  );
};

export default LandingImage;
