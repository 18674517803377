import React from 'react';

import LandingImage from '../landing-image/LandingImage';
import s from './Login.module.scss';
import LoginForm from './login-form';

const Login = () => {
  return (
    <div className={s.wrapper}>
      <LandingImage />
      <LoginForm />
    </div>
  );
};

export default Login;
