import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  GetPropertyType,
  InitialViewType,
  PropertyType,
  TermsType,
} from 'types/property/propertyTypes';

export type PropertySliceType = {
  activeProperty?: PropertyType;
  propertyList?: GetPropertyType[];
  terms?: TermsType;
  initialView?: InitialViewType;
};

export const initialState: PropertySliceType = {};

export const propertySlice = createSlice({
  name: 'propertySlice',
  initialState,
  reducers: {
    setActiveProperty: (state, action: PayloadAction<PropertyType | undefined>) => {
      state.activeProperty = action.payload;
    },
    setPropertyList: (state, action: PayloadAction<GetPropertyType[] | undefined>) => {
      state.propertyList = action.payload;
    },
    setInitialView: (state, action: PayloadAction<InitialViewType | undefined>) => {
      state.initialView = action.payload;
    },
    setTerms: (state, action: PayloadAction<Partial<TermsType | undefined>>) => {
      state.terms = {
        ...state.terms,
        ...action.payload,
      };
    },
    removePropertyById: (state, action) => {
      const idToRemove = action.payload; // Get the id passed in the action
      state.propertyList = state.propertyList?.filter((property) => property.id !== idToRemove);
    },
  },
});

export const { setActiveProperty, setTerms, setInitialView, setPropertyList, removePropertyById } =
  propertySlice.actions;
