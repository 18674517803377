import { FC, PropsWithChildren } from 'react';

import { useGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

const AdminRoleGuard: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { data: currentUser } = useGetCurrentUserQuery();

  const roles = currentUser?.roles;
  const admin = roles?.find((role) => role.roleCode === 'ADM');

  if (currentUser && !admin) {
    return <h1>You dont have access</h1>;
  } else {
    if (currentUser) {
      return <>{children}</>;
    } else {
      return null;
    }
  }
};

export default AdminRoleGuard;
