import { FC, useCallback, useEffect } from 'react';

import MapComponent from 'ui/map/Map';

import { propertyListSelector, termsSelector } from 'selectors/propertySlice.selector';
import { useGetPropertiesMutation } from 'services/properties/propertyApiService';
import { setPropertyList } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { GetPropertyType } from 'types/property/propertyTypes';

import s from './Dashboard.module.scss';

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();

  const [getProperties] = useGetPropertiesMutation();
  const propertyList = useAppSelector(propertyListSelector);
  const terms = useAppSelector(termsSelector);

  useEffect(() => {
    const criteria = {
      pageNumber: 1,
      pageSize: 100,
    };
    getProperties(criteria);
  }, [getProperties]);

  const handleGetPropertyList = useCallback(
    async (criteria) => {
      const res = await getProperties(criteria);
      if ('data' in res) {
        dispatch(setPropertyList(res.data.properties as GetPropertyType[]));
      }
    },
    [dispatch, getProperties],
  );

  useEffect(() => {
    if (terms) {
      const criteria = {
        pageNumber: 1,
        pageSize: 100,
        filters: {
          numberOfBedrooms: terms.bedroomCount === -1 ? undefined : terms.bedroomCount,
          town: terms.searchName,
          priceFrom: terms.moreFilters?.from,
          priceTo: terms.moreFilters?.to,
          propertyTypes: terms.apartOption?.includes('all') ? undefined : terms.apartOption,
          postingType: terms.rentOption === 'rent_sale' ? null : terms.rentOption,
        },
      };

      handleGetPropertyList(criteria);
    }
  }, [handleGetPropertyList, terms]);

  return (
    <div className={s.wrapper}>
      <div>
        <MapComponent properties={propertyList!} />
      </div>
    </div>
  );
};

export default Dashboard;
